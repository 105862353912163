$(document).ready(function() {
  var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
  if(isIE11) {
    $('body').addClass('ie');
  }
  setTimeout(() => {
    $('.spinner__wrapper.main').fadeOut(500, function() {
      $(this).remove();
    });
  }, 500);

  //select
  $('.select').each(function() {
    $(this).select2();
  });

  //print Page
    $('#printPage').on('click', function() {
        window.print();
    });

  // Add smooth scrolling to all links
  $("a.scroll, button.scroll").on('click', function(event) {

    // Make sure this.hash has a value before overriding default behavior
    if (this.hash !== "") {
      // Prevent default anchor click behavior'
      // Add hash (#) to URL when done scrolling (default click behavior)
    //  window.location.hash = hash;
      event.preventDefault();

      // Store hash
      var hash = this.hash;

      // Using jQuery's animate() method to add smooth page scroll
      // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
      var scroll = $(hash).offset().top - 100;
      $('html, body').animate({
        scrollTop: scroll
      }, 800, function(){


      });
    } // End if
  });

  $('.navigationBar ul li').on('click', function() {
    let $that = $(this);
    let $href = $that.children('a').attr('href');
    if($href !== undefined) {
      window.open($href, '_self');
    }

  });

  // lazy loading
  $(window).scroll(lazyload);
  lazyload();

  function lazyload() {
    let wt = $(window).scrollTop();
    let wb = wt + $(window).height();

    $('.lazy').each(function() {
      let ot = $(this).offset().top + 100;
      let ob = ot + $(this).height();

      if(!$(this).hasClass('loaded') && wt <= ob && wb >= ot) {
        console.log($(this), 'loaded');
        $(this).addClass('loaded');
      }
    });
  }


});

(function() {
  var section = $('.section__wrapper');
  var sections = {};
  var i = 0;

  Array.prototype.forEach.call(section, (e) => {
    sections[e.id] = e.offsetTop;
  });

  $(window).on('scroll', () => {
    var scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;

    for (i in sections) {
      if(sections[i] <= scrollPosition) {
        $('a.active').removeClass('active');
        $('a[href*=' + i + ']').addClass('active');
      }
    }
  })

}());
