$(document).ready(function() {
  var $header = $('.header');
  var last = 0;
  function check(){
    var scrollTop = $(window).scrollTop();
    if ( scrollTop > last) {
      last = scrollTop;
      $header.addClass('scrollTop');
    } else if (scrollTop <= 40) {
      $header.removeClass('shadow');
    } else {
      last = scrollTop;
      $header.removeClass('scrollTop');
      $header.addClass('shadow');
    }
  }
  check();
  $(window).scroll(function() {
    check();
  });
  $('.hamburger').on('click', function() {
    var $menu =  $('.header .menu');
    var $backdrop = $('.header .backdrop');
    if($menu.hasClass('open')) {
      $(this).removeClass('open');
      $menu.removeClass('open');
      $backdrop.fadeOut(300);
    } else {
      $(this).addClass('open');
      $menu.addClass('open');
      $backdrop.fadeIn(300);
    }
  });
  $('.backdrop').on('click', function() {
    var $menu =  $('.header .menu');
    var $backdrop = $('.header .backdrop');
    if($menu.hasClass('open')) {
      $(this).removeClass('open');
      $menu.removeClass('open');
      $backdrop.fadeOut(300);
    }
  });

});
